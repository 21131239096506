/* eslint-disable no-unused-vars */
import axios from 'axios';

let apiBU = axios.create({
    baseURL: process.env.VUE_APP_BU_URL,
})

const apiSG = axios.create({
    baseURL: process.env.VUE_APP_SG_URL,
})

const apiCQ = axios.create({
    baseURL: process.env.VUE_APP_CQ_URL,
})

function setToken(token){
    localStorage.JWTtoken = token
}

function setUser(user){
    user = {
        id: user.id,
        username: user.username
    };
    localStorage.user = JSON.stringify(user);
}

function setProduct(product){
    localStorage.product = JSON.stringify(product);
}

function setIsLogged(logged){
    localStorage.isLogged = JSON.stringify(logged)
}

export function getHeader(){
    return {
        headers: {
            Authorization: 'Bearer ' + localStorage.JWTtoken
        }
    }
}

export function isLogged(){
    if (localStorage.isLogged){
        return JSON.parse(localStorage.isLogged)
    }else{
        return false
    }
}

export function getUser(){
    let localUser = localStorage.user
    if (localUser && localUser.id){
        return JSON.parse(localUser);
    }else{
        return false
    }
}

export function getProduct(){
    if (localStorage.product) return JSON.parse(localStorage.product)
    return false;
}


/**          **
*   METHODS   *
*             */

export function logout(){
    setToken('')
    // setUser('')
    // setProduct('')
    setIsLogged(false)
}

export function login (data,product) {
    if (!data.username) return new Promise((resolve,reject)=> reject('Nome de usuário inválido'))
    if (!data.password) return new Promise((resolve,reject)=> reject('Senha inválido'))
    
    setProduct(product)

    if (product.name === 'BU'){
        return new Promise((resolve, reject) => {
            apiBU.post('auth/login', data)
                .then((e) => {
                    if (!e.data.access_token) reject('Não foi possível autenticar.')
                    setToken(e.data.access_token)

                    apiBU.get('auth/me',getHeader())
                        .then((e)=>{
                            if (e.data.type !== 'operador') {
                                logout()
                                reject('Tipo de usuário não permitido')
                            }else{
                                setUser(e.data)
                                setIsLogged(true)
                            }

                            resolve('Login bem sucedido.')
                        }).catch((e)=>{
                        reject('Não foi possível conectar.')
                    })
                }).catch((e)=>{
                reject('Não foi possível conectar.')
            })
        })
    }else if (product.name === 'SG'){
        data.origin = 'operador'

        return new Promise((resolve, reject) => {
            apiSG.post('login', data)
                .then((e) => {
                    if (!e.data.token) reject('Não foi possível autenticar.')

                    setToken(e.data.token)
                    setIsLogged(true)
                    setUser(e.data.user)

                    resolve('Login bem sucedido.')

                }).catch((e)=>{

                let erro = 'internet_connection'
                
                if (e.response && e.response.data && e.response.data.error){
                    erro = e.response.data.error
                }               

                switch (erro) {
                    case 'invalid_credentials':
                        reject('Login ou Senha incorretos.')
                        break;
                    case 'forbidden_access':
                        reject('Acesso suspenso, fale com seu operador.')
                        break;
                    case 'could_not_create_token':
                        reject('Erro ao gerar token.')
                        break;
                    case 'internet_connection':
                        reject('Não foi possível conectar ao servidor.')
                }

                reject('Não foi possivel fazer login.')
            })
        })
    }else if (product.name === 'CQ'){
        data.operator = true
        return new Promise((resolve, reject) => {
            apiCQ.post('admins/login', data)
                .then((e) => {
                    if (!e.data.token) reject('Não foi possível autenticar.')

                    setToken(e.data.token)
                    setIsLogged(true)

                    resolve('Login bem sucedido.')

                }).catch((e)=>{
                console.log(e);
                reject('Login ou Senha incorretos.')
            })
        })
    }
}

/**
 * Returns:
 *  [
 *      {
 *           'id': Int,
 *           'name': String,
 *           'balance': 'Float'
 *      }
 *  ]
 *
 */
export function getSessions(data){
    let product = getProduct()

    if (!product) {
        return new Promise((resolve, reject) => {
            reject('Erro ao processar os dados. Faça login novamente!')
        })
    }

    if (product.name === 'BU'){
        return new Promise((resolve, reject) => {
            const params = { params: {
                ...data,
                date_search: ''
            } }
            
            apiBU.get('/incomes/index2', {...getHeader(), ...params})
            .then((response)=>{

                let resp = {
                    data: response.data.data.map((e)=> {
                        return {
                            id: e.user_id,
                            name: e.name,
                            balance: parseFloat(e.saldo).toFixed(2)
                        }
                    }),
                    totalRecords: response.data.totalRecords
                }

                resolve(resp)
            }).catch((err)=>{
                console.log(err)
                reject('Erro ao coletar dados.')
            })
        })
    } else if (product.name === 'SG'){
        return new Promise((resolve, reject)=>{
            apiSG.get('operador',getHeader())
                .then((e)=>{
                    let resp = {
                        data: e.data.response.map((e)=> {
                            return {
                                id:e.id,
                                name: e.name,
                                balance: e.saldo.Saldo.toFixed(2)
                            }
                        }),
                        totalRecords: e.data.response.length
                    }
                    resolve(resp)
                }).catch(()=>{
                    reject('Não foi possível coletar os dados.')
            })
        })
    } else if (product.name === 'CQ'){

        return new Promise((resolve, reject)=>{
            const params = {
                perPage: 99999,
                withPlayer: true,
                withBalance: true,
            }
            apiCQ.get('/admins/sellers',{params,...getHeader()})
                .then((e)=>{
                    console.log(e);
                    let resp = {
                        data: e.data.data.map((e)=> {
                            console.log(Number(e.player.wallet.balance)/10000);
                            return {
                                id:e.player.user_id,
                                name: e.player.name,
                                balance: (Number(e.player.wallet.balance) / -10000).toFixed(2)
                            }
                        }),
                        totalRecords: e.data.data.length
                    }
                    console.log(resp);
                    resolve(resp)
                }).catch((e)=>{
                    console.log(e);
                    reject('Não foi possível coletar os dados.')
            })
        })
    }
}

export function postBalance(data){
    /**
     * data Example
     * id: 1419
     * name: "revendedor_teste"
     * type: "Débito"
     * value: 76.66
     */

    
    let product = getProduct()
    if (!product) return new Promise((resolve, reject)=> reject('Erro ao processar os dados. Faça login novamente!') )
    if (data.value <= 0) return new Promise((resolve, reject)=> reject('Valor inválido.') )

   if (product.name === 'BU'){
        return new Promise((resolve, reject) => {
            const payload = {
                user_id: data.id,
                income: 0,
                outflow: 0,
            }

            if (data.type == 'Débito'){
                payload.income = data.value
            }else {
                payload.outflow = data.value
            }
            
            
            apiBU.post('incomes',payload,getHeader())
            .then((response)=>{
                if (response.data){
                    resolve('Lançamento realizado')
                }
            }).catch((err)=>{
                reject('Não foi possível completar a ação.')
            })
        })
    }else if (product.name === 'SG') {
        return new Promise((resolve, reject)=>{
            apiSG.post('lancamento-saldo',data,getHeader())
            .then((response)=>{
                resolve(response.data.message)
            }).catch(()=>{
                reject('Não foi possível completar a solicitação.')
            })
        })
    }else if (product.name === 'CQ') {
        const payload = {
            user_id: data.id,
            type: data.type == 'Débito' ? 'DEPOSIT' : 'WITHDRAW',
            value: data.value
        }

        return new Promise((resolve, reject)=>{
            apiCQ.post('/admins/transactions',payload ,getHeader())
            .then((response)=>{
                resolve(response.data.message)
            }).catch(()=>{
                reject('Não foi possível completar a solicitação.')
            })
        })
    }
}

export function getExtract(date = false){
    let product = getProduct()
    if (!product) return false

    if (product.name === 'BU'){
        return new Promise((resolve, reject) => {
            let dateParam
            let params = {
                params: {
                    date_search: ''
                }
            }
            if (date) {
                dateParam = new Date(date)
                const year = dateParam.getFullYear()
                const month = dateParam.getMonth()+1
                const day = dateParam.getDate()
    
                params.params.date_search = `${year}-${month}-${day}`
            }
            
            apiBU.get('incomes',{...getHeader(),...params})
            .then((response)=>{
                let data = response.data
                
                let extract = []
                
                data.forEach((e)=>{
                    // Entrada
                    if (e.income != 0) {
                        extract.push({
                            id: e.id,
                            name: e.name,
                            type: 'income',
                            value: e.income,
                            date: `-`
                        })
                    }
                    
                    
                    // Saída
                    if (e.outflow != 0) {
                        extract.push({
                            id: e.id,
                            name: e.name,
                            type: 'outflow',
                            value: e.outflow,
                            date: `-`
                        })
                    }
                })

                resolve(extract)
                
            }).catch((err)=>{
                console.log(err)  
                reject('Não foi possivel obter os dados.')
            })
        })
    }else if (product.name === 'SG'){
        return new Promise((resolve, reject)=>{
            let url =  'extrato-operador'
            if (date) url = 'extrato-operador?date=' + date

            apiSG.get(url,getHeader())
                .then((response)=>{
                    const data = response.data
                                       
                    let extract = data.map(e =>{
                        return {
                            id: e.id,
                            name:e.name,
                            type: e.tipo === 'Débito'? 'income':'outflow',
                            value:e.valor.toFixed(2),
                            date: e.time
                        }
                    })
                    
                    resolve(extract)
                }).catch((e)=>{
                reject(e)
            })
        })
    }else if (product.name === 'CQ'){
        return new Promise((resolve, reject)=>{
            let dateParam
            if (date) {
                dateParam = new Date(date)
            }else{
                dateParam = new Date()
            }

            const year = dateParam.getFullYear()
            const month = dateParam.getMonth()+1
            const day = dateParam.getDate()
            const params = {
                created_at: [`${year}-${month <= 9?'0'+month: month}-${day <= 9?'0'+day: day}T00:00:00`,`${year}-${month <= 9?'0'+month: month}-${day <= 9?'0'+day: day}T23:59:59`],
                per_page: 99999,
                category: 'OPERATOR',
                status: 'PAID',
            }
            console.log(params);
            apiCQ.get('/admins/payment',{params,...getHeader()})
                .then((response)=>{
                    const data = response.data
                    let extract = data.data.map(e =>{
                        let date = new Date(e.created_at)
                    
                        let hour = date.getHours()
                        let minute = date.getMinutes()
                        let second = date.getSeconds()
                        
                        // Adiciona 0 à esquerda do número
                        if (hour < 10) hour = '0' + hour;
                        if (minute < 10) minute = '0' + minute;
                        if (second < 10) second = '0' + second;
                        return {
                            id: e.id,
                            name:e.user.player.name,
                            type: e.type === 'DEPOSIT'? 'income':'outflow',
                            value: e.value.toFixed(2),
                            date: `${hour}:${minute}:${second}`
                        }
                    })             
                    resolve(extract)
                }).catch((e)=>{
                reject(e)
            })
        })
    }
}

export function getPointGuide(data){
    let product = getProduct()
    if (!product) return false

    if (product.name === 'BU'){
        return new Promise((resolve, reject) => {
            let params = {
                params: {
                    user_id: data.id,
                    date: data.date
                }
            }
            
            apiBU.get('point-guide',{...getHeader(),...params})
            .then((response)=>{
                let responseData = response.data
                if (responseData.premiadas) {
                    responseData.premiadas = responseData.premiadas.filter((e)=>{
                        return e.id != null;
                    })    
                }
                
                resolve(responseData)
            }).catch((err)=>{
                console.log(err)  
                reject('Não foi possivel obter os dados.')
            })
        })
    } else {
        return new Promise((resolve,reject) => {
            reject('Esta função não está disponível no momento.')
        })
    }
}
